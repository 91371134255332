export default {
  name: 'SecaoAcionar',
  components: {},
  props: {
    title: {
      type: String,
      default: 'Como acionar?'
    }
  },
  data: () => ({})
}
